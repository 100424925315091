import * as React from 'react';
import { Field } from 'formik';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';

const DesignRequestForm = () => (
  <Field
    data-cy="designRequestComments"
    name="designRequest.comments"
    rows={5}
    component={Textarea}
    label="Project Details"
  />
);

export default DesignRequestForm;
