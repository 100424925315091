import * as React from 'react';
import cn from 'classnames';
import { products, dimensions } from 'bundles/App/pages/ContactUsPage/formOptions';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Field, FieldArray, useFormikContext } from 'formik';
import Label from 'styleguide/components/Formik/Label/Label';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import { FileCheckRequestFormType } from 'bundles/App/pages/ContactUsPage/types';
import UploadFilesModal from 'styleguide/components/ProductsTable/UploadFilesModal/UploadFilesModal';
import { GrAttachment } from '@react-icons/all-files/gr/GrAttachment';

const FileCheckRequestForm = () => {
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const { values } = useFormikContext<FileCheckRequestFormType>();

  return values.fileCheckRequest ? (
    <>
      <Field
        data-cy="fileCheckRequest.product"
        name="fileCheckRequest.product"
        component={Combobox}
        optionGroups={products}
        label="Product"
        inPlaceError
        creatable
      />
      <Field
        data-cy="fileCheckRequest.dimension"
        name="fileCheckRequest.dimension"
        component={Combobox}
        optionGroups={dimensions}
        label="Dimension"
        inPlaceError
        creatable
      />
      <FieldWrapper
        Label={<Label placement="left">Full Bleed</Label>}
        Input={
          <RadioTabs>
            <Field
              dataCy="fileCheckRequest.noBleed"
              name="fileCheckRequest.fullBleed"
              checked={values.fileCheckRequest.fullBleed === 'No Bleeds'}
              value="No Bleeds"
              label="No Bleeds"
              component={RadioTab}
            />
            <Field
              dataCy="fileCheckRequest.fullBleed"
              name="fileCheckRequest.fullBleed"
              checked={values.fileCheckRequest.fullBleed === 'Full Bleed'}
              value="Full Bleed"
              label="Full Bleed"
              component={RadioTab}
            />
          </RadioTabs>
        }
      />
      <Field
        data-cy="fileCheckRequest.comments"
        name="fileCheckRequest.comments"
        rows={5}
        component={Textarea}
        label="Comments"
        wrapperClassName="!mt-5"
      />
      <div className="!mt-5">
        <div
          className="text-sm font-hvMedium flex items-center cursor-pointer"
          onClick={() => setShowUploadModal(true)}
          tabIndex={0}
          data-cy="fileCheckRequest.addAttachments"
          role="button"
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setShowUploadModal(true);
            }
          }}
        >
          <GrAttachment className="mr-2" />
          Add attachment
        </div>
        <FieldArray
          data-cy="fileCheckRequest.attachments"
          name="fileCheckRequest.attachments"
          render={arrayHelpers => (
            <>
              {(values.fileCheckRequest.attachments || []).map(attachment => (
                <a
                  key={attachment.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={attachment.url}
                  className={cn(
                    !!attachment.url && !!attachment === true
                      ? 'flex-grow break-all text-blue underline'
                      : 'flex-grow cursor-default break-all no-underline',
                  )}
                >
                  <p
                    className={cn(
                      !!attachment.url && !!attachment === true && 'text-blue',
                      'm-0 flex-grow text-sm leading-5 md:leading-4 mt-2',
                    )}
                  >
                    {attachment.name}
                  </p>
                </a>
              ))}
              {showUploadModal && (
                <UploadFilesModal
                  onClose={() => setShowUploadModal(false)}
                  onAddUpload={(_dispatch, _owner, upload) => {
                    arrayHelpers.push(upload);
                  }}
                  onRemoveUpload={(_dispatch, id) => {
                    const index = values.fileCheckRequest.attachments.findIndex(upload => upload.id === id);
                    arrayHelpers.remove(index);
                  }}
                  filesFromProps={values.fileCheckRequest.attachments}
                />
              )}
            </>
          )}
        />
      </div>
    </>
  ) : null;
};

export default FileCheckRequestForm;
