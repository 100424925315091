import * as React from 'react';
import { boxType, boxesDimensions } from 'bundles/App/pages/ContactUsPage/formOptions';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Field, useFormikContext } from 'formik';
import { BoxesRequestFormType } from 'bundles/App/pages/ContactUsPage/types';
import Label from 'styleguide/components/Formik/Label/Label';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';
import Input from 'styleguide/components/Formik/Input/Input';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';

const BoxesRequestForm = () => {
  const { values } = useFormikContext<BoxesRequestFormType>();

  return values.boxesRequest ? (
    <>
      <Field
        data-cy="boxesRequest.product"
        name="boxesRequest.product"
        component={Combobox}
        options={boxType}
        label="Box Type"
        inPlaceError
        creatable
      />
      <Field
        data-cy="boxesRequest.dimension"
        name="boxesRequest.dimension"
        component={Combobox}
        options={boxesDimensions}
        label="Dimension"
        inPlaceError
        creatable
      />
      <FieldWrapper
        className="mt-1"
        Label={<Label placement="left">Corrugated Material</Label>}
        Input={
          <RadioTabs>
            <Field
              dataCy="boxesRequest.paperType.white"
              name="boxesRequest.paperType"
              checked={values.boxesRequest.paperType === 'White Corrugated Cardboard'}
              value="White Corrugated Cardboard"
              label="White Corrugated Cardboard"
              component={RadioTab}
            />
            <Field
              dataCy="boxesRequest.paperType.brown"
              name="boxesRequest.paperType"
              checked={values.boxesRequest.paperType === 'Kraft (Brown) Corrugated Cardboard'}
              value="Kraft (Brown) Corrugated Cardboard"
              label="Kraft (Brown) Corrugated Cardboard"
              component={RadioTab}
            />
          </RadioTabs>
        }
      />
      <FieldWrapper
        className="!mt-4"
        Label={<Label placement="left">Number of Sides</Label>}
        Input={
          <RadioTabs>
            <Field
              dataCy="boxesRequest.sides.single"
              name="boxesRequest.sides"
              checked={values.boxesRequest.sides === 'Single Sided'}
              value="Single Sided"
              label="Single Sided"
              component={RadioTab}
            />
            <Field
              dataCy="boxesRequest.sides.double"
              name="boxesRequest.sides"
              checked={values.boxesRequest.sides === 'Double Sided'}
              value="Double Sided"
              label="Double Sided"
              component={RadioTab}
            />
          </RadioTabs>
        }
      />
      <FieldWrapper
        className="!mt-4"
        Input={
          <Field
            data-cy="boxesRequest.documentCount"
            name="boxesRequest.documentCount"
            component={Input}
            label="Number of Boxes"
          />
        }
      />
      <FieldWrapper
        className="!mt-4"
        Input={
          <Field
            data-cy="boxesRequest.comments"
            name="boxesRequest.comments"
            rows={5}
            component={Textarea}
            label="Comments"
          />
        }
      />
    </>
  ) : null;
};

export default BoxesRequestForm;
