import * as React from 'react';
import { Field } from 'formik';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';

const GeneralRequestForm = () => (
  <Field
    data-cy="generalRequestComments"
    name="generalRequest.comments"
    rows={5}
    component={Textarea}
    label="Comments"
  />
);

export default GeneralRequestForm;
