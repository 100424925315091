import * as React from 'react';
import { products, dimensions, paperType } from 'bundles/App/pages/ContactUsPage/formOptions';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Field, useFormikContext } from 'formik';
import { QuoteRequestFormType } from 'bundles/App/pages/ContactUsPage/types';
import Label from 'styleguide/components/Formik/Label/Label';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';
import Input from 'styleguide/components/Formik/Input/Input';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';

const QuoteRequestForm = () => {
  const { values } = useFormikContext<QuoteRequestFormType>();

  return (
    <>
      {values.quoteRequest && (
        <>
          <Field
            data-cy="quoteRequest.product"
            name="quoteRequest.product"
            component={Combobox}
            optionGroups={products}
            label="Product"
            inPlaceError
            creatable
          />
          <Field
            data-cy="quoteRequest.dimension"
            name="quoteRequest.dimension"
            component={Combobox}
            optionGroups={dimensions}
            label="Dimension"
            inPlaceError
            creatable
          />
          <Field
            data-cy="quoteRequest.paperType"
            name="quoteRequest.paperType"
            component={Combobox}
            optionGroups={paperType}
            label="Paper Type"
            inPlaceError
            creatable
          />
          <FieldWrapper
            Label={<Label placement="left">Orientation</Label>}
            Input={
              <RadioTabs>
                <Field
                  dataCy="quoteRequest.orientation.portrait"
                  name="quoteRequest.orientation"
                  checked={values.quoteRequest.orientation === 'portrait'}
                  value="portrait"
                  label="Portrait"
                  component={RadioTab}
                />
                <Field
                  dataCy="quoteRequest.orientation.landscape"
                  name="quoteRequest.orientation"
                  checked={values.quoteRequest.orientation === 'landscape'}
                  value="landscape"
                  label="Landscape"
                  component={RadioTab}
                />
              </RadioTabs>
            }
          />
          <Field
            data-cy="quoteRequest.documentCount"
            name="quoteRequest.documentCount"
            component={Input}
            label="Number of Documents"
            wrapperClassName="mt-5"
          />
          <Field
            data-cy="quoteRequest.pagesPerDocument"
            name="quoteRequest.pagesPerDocument"
            component={Input}
            label="Pages per Document"
            wrapperClassName="mt-5"
          />
          <FieldWrapper
            className="!mt-5"
            Label={<Label placement="left">Number of Sides</Label>}
            Input={
              <RadioTabs>
                <Field
                  dataCy="quoteRequest.sides.single"
                  name="quoteRequest.sides"
                  checked={values.quoteRequest.sides === 'Single Sided'}
                  value="Single Sided"
                  label="Single Sided"
                  component={RadioTab}
                />
                <Field
                  dataCy="quoteRequest.sides.double"
                  name="quoteRequest.sides"
                  checked={values.quoteRequest.sides === 'Double Sided'}
                  value="Double Sided"
                  label="Double Sided"
                  component={RadioTab}
                />
              </RadioTabs>
            }
          />
          <FieldWrapper
            className="!mt-5"
            Label={<Label placement="left">Color</Label>}
            Input={
              <RadioTabs>
                <Field
                  dataCy="quoteRequest.color.colored"
                  name="quoteRequest.color"
                  checked={values.quoteRequest.color === 'Color'}
                  value="Color"
                  label="Color"
                  component={RadioTab}
                />
                <Field
                  dataCy="quoteRequest.color.blackAndWhite"
                  name="quoteRequest.color"
                  checked={values.quoteRequest.color === 'Black and White'}
                  value="Black and White"
                  label="Black and White"
                  component={RadioTab}
                />
              </RadioTabs>
            }
          />
        </>
      )}
      <Field
        data-cy="quoteRequest.comments"
        name="quoteRequest.comments"
        rows={5}
        component={Textarea}
        label="Comments"
        wrapperClassName="!mt-5"
      />
    </>
  );
};

export default QuoteRequestForm;
